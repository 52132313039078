<template>
  <Card class="integration-vitec-units">
    <template #header>
      <slot name="header" />
    </template>
    <template #default>
      <Loader :value="promises.fetch" theme="overlay">
        <table>
          <tr>
            <th>Enhet i Vitec</th>
            <th>Enhet i Kvass</th>
          </tr>
          <tbody>
            <tr v-for="item in vitec" :key="item.value">
              <td>
                <span v-if="item.label">{{ item.label }}</span>
                <span v-else class="integration-vitec-units__notset">"Alfanavn" ikke definert</span>
              </td>
              <td>
                <select :value="value.map[item.value]" @input="$ev => setMap(item, $ev)">
                  <option :value="null"><label>Opprett ny enhet</label></option>
                  <option :value="false"><label>Hopp over</label></option>
                  <option v-for="kitem in kvass" :key="kitem.value" :value="kitem.value">{{ kitem.label }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </Loader>
    </template>
    <template #footer>
      <Alert v-if="hasMissingVitecLabels" theme="error">
        <p>
          En eller flere enheter i Vitec mangler "Alfanavn". For at integrasjonen mellom Kvass og Vitec skal fungere må
          dette være på plass.
        </p>
        <p>Kom tilbake hit så snart du har lagt inn dette for å fullføre aktiveringen eller hopp over enhetene</p>
      </Alert>
      <Alert v-if="hasDuplicateSelection" theme="error"> Du har brukt en enhet i Kvass flere ganger. </Alert>
      <ButtonComponent
        label="Fullfør"
        :icon="['fal', 'arrow-to-right']"
        theme="primary"
        :disabled="isDisabled"
        @click="submit"
        :promise="promises.submit"
      />
    </template>
  </Card>
</template>

<script>
import Validator from 'validatorjs'
import { ValidatorSettings } from './activate'

import Card from '@kvass/card'
import { ButtonComponent } from 'vue-elder-button'
import { LoaderComponent as Loader } from 'vue-elder-loader'
import { AlertComponent as Alert } from 'vue-elder-alert'

const noDupes = arr => {
  return arr.every(x => arr.filter(u => u === x).length <= 1)
}

function convertTarget(val) {
  switch (val) {
    case 'false':
      return false
    case '':
      return null
    default:
      return val
  }
}

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      promises: {
        fetch: null,
        submit: null,
      },
      vitec: [],
      kvass: [],
    }
  },
  computed: {
    isDisabled() {
      return this.hasMissingVitecLabels || this.hasDuplicateSelection
    },
    hasMissingVitecLabels() {
      const hasMissing = this.vitec.filter(v => !v.label)

      if (!hasMissing.length) return false

      return !hasMissing.every(({ value }) => (this.value.map || {})[value] === false)
    },
    hasDuplicateSelection() {
      return !noDupes(Object.values(this.value.map || {}).filter(Boolean))
    },
  },
  methods: {
    fetch() {
      this.promises.fetch = fetch(`https://${this.value.account}/api/integration/${this.value.id}/callbacks/map`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.value.token}`,
        },
      })
        .then(res => {
          if (!res.ok) throw new Error()
          return res.json()
        })
        .then(({ map, kvass, vitec }) => {
          this.$emit('input', { ...this.value, map })

          this.kvass = kvass
          this.vitec = vitec
        })
        .catch(() => this.$emit('navigate', 'activate'))
    },
    submit() {
      if (this.isDisabled) return

      this.promises.submit = fetch(`https://${this.value.account}/api/integration/${this.value.id}/callbacks/map`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.value.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.value.map),
      }).then(res => {
        if (!res.ok) throw new Error()
        return this.$emit('navigate', 'completed')
      })
    },
    setMap(item, event) {
      let value = convertTarget(event.target.value)
      this.$emit('input', { ...this.value, map: { ...this.value.map, [item.value]: value } })
    },
  },
  mounted() {
    if (new Validator(this.value, ValidatorSettings.rules).passes() === false) return this.$emit('navigate', 'activate')

    this.fetch()
  },
  components: {
    Loader,
    ButtonComponent,
    Card,
    Alert,
  },
}
</script>

<style lang="scss">
.integration-vitec-units {
  table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      padding: 0.75rem;
      text-align: left;
    }

    tbody tr:nth-child(odd) {
      background-color: hsla(var(--border-color-h), var(--border-color-s), var(--border-color-l), 0.3);
    }
  }

  &__notset {
    font-style: italic;
    opacity: 0.7;
  }

  .alert p {
    margin-top: 0;
  }
}
</style>
