<template>
  <Card class="integration-vitec-basic" tag="form" @submit.prevent="submit">
    <template #header>
      <slot name="header" />
    </template>
    <template #default>
      <InputComponent
        v-model="value['account']"
        required
        prefix="https://"
        :label="labels['account']"
        :is-valid="validator.fields['account'].valid"
        :validation-message="validator.fields['account'].error"
      />
      <InputComponent
        v-model="value['id']"
        required
        :label="labels['id']"
        :is-valid="validator.fields['id'].valid"
        :validation-message="validator.fields['id'].error"
      />
      <InputComponent
        v-model="value['token']"
        :label="labels['token']"
        required
        :is-valid="validator.fields['token'].valid"
        :validation-message="validator.fields['token'].error"
      />
      <Alert>
        Fyll inn informasjon sendt per mail:&nbsp;
        <a target="_blank" href="https://help.kvass.no/nb/articles/14534">Les mer om oppsett av integrasjon her</a>
      </Alert>
    </template>
    <template #footer>
      <Alert v-if="licenseError" theme="error">
        Antall enheter i Vitec Next er høyere en tillatt. Vennligst ta kontakt med prosjekteier for å øke grensen.
      </Alert>
      <ButtonComponent
        label="Neste"
        theme="primary"
        :promise="promise"
        :disabled="!validator.passes"
        :icon="['fal', 'arrow-to-right']"
      />
    </template>
  </Card>
</template>

<script>
import Validator from '@/mixins/validator-v2'
import Card from '@kvass/card'
import { InputComponent } from 'vue-elder-input'
import { ButtonComponent } from 'vue-elder-button'
import { AlertComponent as Alert } from 'vue-elder-alert'

const ValidatorSettings = {
  labels: {
    account: 'Konto URL',
    id: 'Integrasjons ID',
    token: 'Integrasjonsnøkkel',
  },
  rules: {
    account: 'required|domain',
    id: 'required',
    token: 'required',
  },
}

export { ValidatorSettings }
export default {
  mixins: [
    Validator({
      data: 'value',
      labels: 'labels',
      rules: 'rules',
    }),
  ],
  props: {
    value: Object,
  },
  data() {
    return {
      promise: null,
      licenseError: false,
      labels: ValidatorSettings.labels,
      rules: ValidatorSettings.rules,
    }
  },
  methods: {
    submit() {
      if (!this.validator.passes) return

      this.promise = fetch(`https://${this.value.account}/api/integration/${this.value.id}/callbacks/init`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.value.token}`,
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(this.$route.query),
      }).then(res => {
        if (!res.ok) {
          if (res.status === 402) this.licenseError = true
          throw new Error('Could not init')
        }
        return this.$emit('navigate', 'units')
      })
    },
  },
  components: {
    InputComponent,
    ButtonComponent,
    Card,
    Alert,
  },
}
</script>

<style lang="scss">
.integration-vitec-basic {
  max-width: 600px;

  .kvass-card__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .alert p {
    margin-top: 0;
  }
}
</style>
